import React from "react";
import "./RadioButtonGroup.css";

/**
 * @author Rajesh
 *@description provide single radioButton in radioButtonGrop options
 * @param {Object} Object { label, value, checked, onChange }
 * @returns single RadioButton in RadioButtonGroup options
 */
const RadioButton = ({ label, value, checked, onChange }) => {
    return (
        <label className='radio-button'>
            <input
                className='button-input'
                type="radio"
                value={value}
                checked={checked}
                onChange={onChange}
            />
            {label}
        </label>
    );
};

/**
 * @author Rajesh
 *@description RadioButtonGroup provides options to select nach option
 * @param {Object} Object {natchSelected, setNatchSelected}
 * @returns nach select options {cancel, suspend, revoke, amend}
 */
const RadioButtonGroup = ({ natchSelected, setNatchSelected }) => {

    const handleOptionChange = (event) => {
        setNatchSelected(event.target.value);
    };

    return (
        <div className='radio-button-group'>
            <RadioButton
                label="Cancel NACH"
                value="cancel"
                checked={natchSelected === 'cancel'}
                onChange={handleOptionChange}
            />
            <RadioButton
                label="Suspend NACH"
                value="suspend"
                checked={natchSelected === 'suspend'}
                onChange={handleOptionChange}
            />
            <RadioButton
                label="Revoke NACH"
                value="revoke"
                checked={natchSelected === 'revoke'}
                onChange={handleOptionChange}
            />
            <RadioButton
                label="Amend NACH"
                value="amend"
                checked={natchSelected === 'amend'}
                onChange={handleOptionChange}
            />
        </div>
    );
};

export default RadioButtonGroup;