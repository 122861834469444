import React from "react";
import "./Success.css";
import successImage from "../../resources/images/Success.svg";
const Success = ({ uniqueId }) => {
  return (
    <div className="success-container">
      <div className="success-content">
        <div className="success-icon">
          <img className="svg-image" src={successImage} alt="chal rha he" />
        </div>
        <h1>Successful</h1>
        <p>
          Your request has been successfully logged. Please find your unique
          reference ID <span className="uniqueId">{uniqueId}</span> for future use. Someone will reach out to you within 2 working days.
        </p>
      </div>
    </div>
  );
};

export default Success;