import React from "react";
import "./Fail.css";
import errorImage from "../../resources/images/Error.svg";

const Fail = () => {
  return (
    <div className="failed-container">
      <div className="failed-content">
        <div className="failed-icon">
          <img className="svg-image" src={errorImage} alt="chal rha he" />
        </div>
        <h1>Error</h1>
        <p>
          Your request has not been successfully logged.
        </p>
      </div>
    </div>
  );
};

export default Fail;