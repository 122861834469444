import React, { useState, useEffect } from "react";
import "./Home.css";
import RadioButtonGroup from "../../components/radioButtonGroup/RadioButtonGroup";
import SubmitForm from "../../components/submitForm/SubmitForm";
import Alert from "../../components/alert/Alert";
import Success from "../success/Success";
import Fail from "../fail/Fail";

/**
 * @author Rajesh
 * @description Home screen contains eNatch select options and form as per selection
 * @returns Home Screen
 */
const Home = () => {
  const [natchSelected, setNatchSelected] = useState("cancel");

  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [uniqueId, setUniqueId] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, [natchSelected]);
  return (
    <div className="home-screen">
      {open && (
        <Alert
          type={alertType}
          message={alertMessage}
          handleClose={handleClose}
        />
      )}
      {!success && !fail && (
        <div>
          <div className="selection-area">
            <h1 className="selection-heading">Select any one of these</h1>
            <div className="selection">
              <RadioButtonGroup
                natchSelected={natchSelected}
                setNatchSelected={setNatchSelected}
              />
            </div>
          </div>

          <SubmitForm
            natchSelected={natchSelected}
            setOpen={setOpen}
            setAlertType={setAlertType}
            setAlertMessage={setAlertMessage}
            setSuccess={setSuccess}
            setFail={setFail}
            setUniqueId={setUniqueId}
          />
        </div>
      )}

      {success && <Success  uniqueId={uniqueId}/>}
      {fail && <Fail />}
    </div>
  );
};

export default Home;
