import React from "react";
import "./NotFoundPage.css";

const NotFoundPage = () => {
  return (
    <div className="pageError-container">
      <div className="pageError-content">
        <h1 className="pageError-heading">404</h1>
        <h2 className="pageError-subHeading">Oops! Page Not Found</h2>
        <p className="pageError-message">
          The page you are looking for does not exist. It might have been moved
          or deleted.
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;
