import React from 'react';
import "./Header.css";
import logo from "../../resources/images/Group10.svg";


/**
 * @author Rajesh
 * @description Header contains company logo
 * @returns Header component
 */
const Header = () => {
    return (
        <div className='header-section'>
            <div className='header'>
                <img className='header-image' src={logo} alt="logo" />
            </div>
            <div className='ruler'></div>
            
        </div>
    )
}

export default Header;
