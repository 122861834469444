import React, { useState, useEffect } from "react";
import "./Alert.css";
import error from "../../resources/images/error-24.svg";
import success from "../../resources/images/success-35.svg";

/**
 * @author Rajesh
 * @description Alert component alert the message with type as failure or success
 * @param {Object} Object {type, message, handleClose}
 * @returns Alert component
 */
const Alert = ({ type, message, handleClose }) => {
  const [color, setColor] = useState("red");
  const [icons, setIcons] = useState(null);
  const [text, setText] = useState(null);
  const [bgColor, setBgColor] = useState(null);

  useEffect(() => {
    switch (type) {
      case "failure":
        setColor("red");
        setIcons(error);
        setText("Failure");
        break;
      case "success":
        setColor("#55a640");
        setIcons(success);
        setText("Successfully");
        setBgColor("#f4fef6");
        break;
      default:
        setColor("yellow");
        setIcons(error);
        setText("Warning");
    }
  }, [type]);

  const styleParentDiv = {
    border: `1.2px solid ${color}`,
    backgroundColor: bgColor,
    animation: "alert - animation 0.5s ease-in -out",
  };

  const styleChildDiv = {
    display: "flex",
    alignItems: "center",
  };

  const iconImage = {
    height: "30px",
    width: "30px",
    margin: "10px 10px 30px 18px",
  };
  //useEffect to close alert after 2.5 seconds
  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 2500);
  }, []);

  return (
    <div className="styleParentDiv" style={styleParentDiv}>
      <div style={styleChildDiv}>
        {icons && <img style={iconImage} src={icons} alt={type} />}
        <div>
          <p style={{ color: color, fontWeight: "bold" }}>{text}</p>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default Alert;
